import React, { useEffect } from 'react'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion'
// import Button from '../Button'
import Card from '../Card'
// import ContactForm from '../ContactForm'
import ContactInfo from '../ContactInfo'
import FunFact from '../FunFact'
import Hero from '../Hero'
import Web3Hero from '../Hero/Web3Hero'
import Web3Header from '../Header/Web3Header'
import IconBox from '../IconBox'
import Modal from '../Modal'
import Section from '../Section'
import SectionHeading from '../SectionHeading'
import ImageBoxSlider from '../Slider/ImageBoxSlider'
import RoadmapSlider from '../Slider/RoadmapSlider'
import Spacing from '../Spacing'
import Team from '../Team'
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
// const Item = styled(Paper)(({ theme }) => ({
import { Stack } from "@mui/material";
import { ConnectButton } from '@rainbow-me/rainbowkit';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { BorderAllRounded } from '@mui/icons-material'
import './Portal.css';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#FFC225',
  borderColor: '#0057A6',
  ...theme.typography.body2,
  padding: theme.spacing(4, 0),
  textAlign: 'center',
  color: 'white',
}));

function Copyright(props) {
  return (
    <Typography variant="body2" color="color.white" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="#">
        catchcoin
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function Index() {
  pageTitle('Catchcoin: Pioneering Foot Traffic Growth | Real Cash Rewards');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };

  return (
    <>
      <Section tag='section' id='portal' className="container">
        <Section className="row align-content-center">
          <Section className="col-12">

            <Container component="main" maxWidth="xs" sx={{
              background:'#0057A6',
              mt: 20,
              pt: 5,
              pb: 5
            }}>
              <CssBaseline />
              <Box
                sx={{
                  marginTop: 8,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <img src="/images/logo_white.svg" alt="Logo" height={96} width={96} sx={{
                  Border: "20px",
                  borderColor: "white"
                }} />
                <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
                  Sign in
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                  {/* <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                  <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                  /> */}


                  <ConnectButton.Custom>
                    {({
                      account,
                      chain,
                      openAccountModal,
                      openChainModal,
                      openConnectModal,
                      authenticationStatus,
                      mounted,
                    }) => {
                      // Note: If your app doesn't use authentication, you
                      // can remove all 'authenticationStatus' checks
                      const ready = mounted && authenticationStatus !== "loading";
                      const connected =
                        ready &&
                        account &&
                        chain &&
                        (!authenticationStatus || authenticationStatus === "authenticated");

                      return (
                        <div
                          {...(!ready && {
                            "aria-hidden": true,
                            style: {
                              opacity: 0,
                              pointerEvents: "none",
                              userSelect: "none",
                              paddingTop: "10px",
                            },
                          })}
                        >
                          {(() => {
                            if (!connected) {
                              return (
                                // <Button
                                //   variant="contained"
                                //   color="primary"
                                //   onClick={openConnectModal}
                                //   type="button"
                                //   sx={{
                                //     fontWeight: 'bold',
                                //     borderColor: 'white',
                                //   }}
                                // >
                                //   Connect Wallet
                                // </Button>
                                <>

                                  <Button
                                    type="submit"
                                    fullWidth
                                    onClick={openConnectModal}
                                    variant="contained"
                                    color="info"
                                    sx={{ mt: 3, mb: 2 }}
                                  >
                                    Sign In With Web3
                                  </Button>


                                </>
                              );
                            }
                            // console.log(chain.id);
                            if (chain.unsupported && chain.id !== 56 && chain.id !== 97) {
                              return (
                                <Button
                                  fullWidth
                                  onClick={openChainModal}
                                  variant={"contained"}
                                  color={"error"}
                                  sx={{ mt: 3, mb: 2 }}

                                >
                                  Wrong network
                                </Button>
                              );
                            }

                            return (
                              <Stack spacing={1}>

                                <TextField
                                  margin="normal"
                                  required
                                  fullWidth
                                  id="email"
                                  label="Wallet Address"
                                  defaultValue={account.address}
                                  name="email"
                                  autoComplete="email"
                                  autoFocus
                                />
                                {/* <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                  /> */}
                                <Typography className=''>Network:  {chain.name} {chain.id}</Typography>

                                <ConnectButton
                                  accountStatus={{
                                    smallScreen: 'address',
                                    largeScreen: 'address',
                                  }}
                                  sx={{
                                    mt: 2
                                  }}
                                />



                              </Stack>
                            );
                          })()}
                        </div>
                      );
                    }}
                  </ConnectButton.Custom>



                  <Grid container>
                    <Grid item xs>
                      <Link href="#" variant="body2">
                        Forgot password?
                      </Link>
                    </Grid>
                    <Grid item>
                    </Grid>
                  </Grid>
                  Don't have an account?&nbsp;
                  <Link href="#" variant="body2">
                    {"Sign Up"}
                  </Link>
                </Box>
              </Box>
              <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>

            <Section tag='p'></Section>
            <Spacing lg='25' md='15' />
            <Spacing lg='0' md='40' />
          </Section>
        </Section>
        <Spacing lg='100' md='70' />
      </Section>

      <Spacing lg='65' md='35' />
    </>
  )
}
