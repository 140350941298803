import { Icon } from '@iconify/react';
import React from 'react'
import Slider from "react-slick";
import Section from '../Section';
import CardStype2 from '../Card/CardStype2'

export default function RoadmapSlider() {
  const data = [
    {
      phase: 'Phase 1 - 2024',
      title: 'Whitelisting',
      subtitle: `<ul>
      <li><del>Introductory AMA (x.com)</del></li>
      <li><del>Website Launch & Social Media Presence</del></li>
      <li><del>Corporate KYC Compliance</del></li>
      <li><del>Whitelisting Initiatives</del></li>
      <li><del>Smart Contract Creation</del></li>
      <li><del>Smart Contract Audit Cyberscope</del></li>
  </ul>
  `,
    }, {
      phase: 'Phase 2 - 2024',
      title: 'Token Launch',
      subtitle: `<ul>
<li><del>AMA (x.com)</del></li>
<li><del>PinkSale Launchpad</del></li>
<li><del>Pancakeswap Trading Pair Integration</del></li>
<li><del>BSC to BASE Contract Migration</del></li>
<li><del>CoinMarketCap</del></li>
<li><del>CoinGecko</del></li>
</ul>`,
    },
    {
      phase: 'Phase 3 - 2024',
      title: 'Platform Development',
      subtitle: `<ul>
      <li>New iOS/Android UI's</li>
      <li>Streamline portal UI</li>
      <li>Integrate portal UI</li>
  </ul>`,
    }, {
      phase: 'Phase 4 - 2024',
      title: 'Demo Preparation',
      subtitle: `<ul>
      <li>Develop various smart contracts</li>
      <li>Demo of platform's features/capabilities</li>
      <li>Beta version of catchcoin platform for select users</li>
      <li>Integrate smart contracts into DApp</li>
  </ul>`
    }, {
      phase: 'Phase 5 - 2024',
      title: 'Relationship Building',
      subtitle: `<ul>
      <li>Forge strategic partnerships for initial rollout</li>
      <li>Integrate stablecoin functionality into the platform</li>
      <li>Unveil the platform to the public</li>
      <li>Launch the iOS/Android app on respective stores</li>
      <li>Initiate real-world deployment of catchcoin technology in selected locations for market testing and initial introduction</li>
  </ul>
  `,
    }, {
      phase: 'Phase 6 - 2025',
      title: 'Digital Advertising Roll Out',
      subtitle: `<ul>
      <li>Scaling up presence and operations to achieve a national brand rollout</li>
      <li>Phase 1 User Acquisition</li>
      <li>Phase 2 Merchant Acquisition</li>
  </ul>`,
    }, {
      phase: 'Phase 7 - 2026',
      title: 'International Roll out',
      subtitle: `<ul>
      <li>Begin international brand ambassador program</li>
      <li>Identify and integrate brand ambassadors</li>
      <li>Initiate international rollout</li>
  </ul>
  `,
    },
  ]
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        "slick-prev slick-arrow" +
        (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="material-symbols:chevron-left-rounded" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="material-symbols:chevron-right-rounded" />
    </div>
  );
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1285,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  return (
    <Slider {...settings} className='cs-gap-24 cs-awwow_style_1 cs-type1'>
      {data.map((item, index) => (
        <Section key={index}>
          <CardStype2
            cardNumber={index + 1}
            phase={item.phase}
            title={item.title}

            subtitle={item.subtitle}
          />
        </Section>
      ))}
    </Slider>
  )
}
