import { Icon } from '@iconify/react'
import React from 'react'
import { Link } from 'react-router-dom'
import Cta from '../Cta'
import Section from '../Section'
import './footer.scss'

export default function Footer() {
  return (
    <>
    {/* <Section tag='footer_new'>
      <div class="demo-wrap">
        <img
          class="demo-bg"
          src="https://assets.digitalocean.com/labs/images/community_bg.png"
          alt=""
        />
        <div class="demo-content">
          <h1>Hello World!</h1>
        </div>
      </div>
    </Section> */}


    <Section tag='footer' className="cs-footer_wrap cs-bg" style={{backgroundImage:'url(/images/footer.jpg)', backgroundSize: 'cover', backgroundOpacity: 0.5}}>
      <Section className="container">
        <Cta/>
      </Section>
      <Section tag='footer' className="cs-footer text-center">
        <Section className="container">
          <Section className="cs-height_50 cs-height_lg_70" />
          <Section className="cs-footer_logo">
            <img src="/images/logo.png" alt="logo" className="cs-hide_dark" width={"128px"} />
            {/* <img src="/images/logo_footer.svg" alt="logo" className="cs-hide_white" /> */}
            <img src="/images/logo.png" alt="logo" className="cs-hide_white" width={"128px"} />

            </Section>
          <Section className="cs-height_25 cs-height_lg_25" />
          <Section className="cs-social_btns cs-center">
            <Link to="https://www.facebook.com/catchcoin" target="_blank" className="cs-center cs-primary_color cs-accent_bg_hover cs-light_bg cs-white_color_hover">
              <Icon icon="fa6-brands:facebook-f" />
            </Link>
            <Link to="https://twitter.com/catchcoinmeta" target="_blank" className="cs-center cs-primary_color cs-accent_bg_hover cs-light_bg cs-white_color_hover">
              <Icon icon="fa6-brands:twitter" />            
            </Link>
            <Link to="https://medium.com/@catchcoin" target="_blank" className="cs-center cs-primary_color cs-accent_bg_hover cs-light_bg cs-white_color_hover">
              <Icon icon="fa6-brands:medium" />
            </Link>
            <Link to="https://t.me/catchcoindiscussion" target="_blank" className="cs-center cs-primary_color cs-accent_bg_hover cs-light_bg cs-white_color_hover">
              <Icon icon="fa6-brands:telegram" />
            </Link>
            <Link to="https://github.com/catchcoinofficial/CATCH/" target="_blank" className="cs-center cs-primary_color cs-accent_bg_hover cs-light_bg cs-white_color_hover">
              <Icon icon="fa6-brands:github" />
            </Link>
            <Link to="https://www.reddit.com/r/catchcoin/" target="_blank" className="cs-center cs-primary_color cs-accent_bg_hover cs-light_bg cs-white_color_hover">
              <Icon icon="fa6-brands:reddit" />            
            </Link>

            <Link to="https://discord.gg/prFbR5tc5a" target="_blank" className="cs-center cs-primary_color cs-accent_bg_hover cs-light_bg cs-white_color_hover">
              <Icon icon="fa6-brands:discord" />
            </Link>
            <Link to="https://tiktok.com/@catchcoinofficial" target="_blank" className="cs-center cs-primary_color cs-accent_bg_hover cs-light_bg cs-white_color_hover">
              <Icon icon="fa6-brands:tiktok" />              
            </Link>
          </Section>
        </Section>
        <Section className="footer-links">
          <ul class="nav justify-content-center pt-3 mt-3">
            <li class="nav-item"><Link to="/terms" class="nav-link px-2 ">Terms</Link></li>
            <li class="nav-item"><Link to="/privacy" class="nav-link px-2 ">Privacy</Link></li>
            {/* <li class="nav-item"><Link to="/news" class="nav-link px-2 ">News</Link></li> */}
          </ul>
        </Section>

        <Section className="cs-height_45 cs-height_lg_25" />
        <Section className="container">
          <Section className="cs-copyright text-center">Copyright © 2024. All Rights Reserved</Section>
          { /*  by <span className="cs-primary_font cs-primary_color">CC Innovations Ltd</span> */ }
        </Section>
        <Section className="cs-height_25 cs-height_lg_25" />
      </Section>
    </Section>
    </>
  )
}
