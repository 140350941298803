import { Route, Routes } from "react-router-dom";
import ErrorPage from "./components/ErrorPage";
import Home from "./components/Pages/Home";
import Index from "./components/Pages/Index";
import Portal from "./components/Pages/Portal";
import { Link } from 'react-router-dom';

import Terms from "./components/Pages/Terms";
import Privacy from "./components/Pages/Privacy";
// import News from "./components/Pages/News";
import Spacing from "./components/Spacing";
import { ToastContainer } from 'react-toastify';
import WalletContext from "./contexts/walletContext";
import '@rainbow-me/rainbowkit/styles.css';
import {
  QueryClientProvider,
  QueryClient,
} from "@tanstack/react-query";


import { red } from "@mui/material/colors";


import {
  getDefaultWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import {
  mainnet,
  bsc,
  bscTestnet,
  polygon,
} from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import Web3Header from "./components/Header/Web3Header";

import CookieConsent from "react-cookie-consent";

const { chains, publicClient } = configureChains(
  [bsc, bscTestnet],
  [
    alchemyProvider({ apiKey: process.env.ALCHEMY_ID }),
    publicProvider()
  ]
);

const { connectors } = getDefaultWallets({
  appName: 'catchcoin',
  projectId: '0b8450e7b8ee4f8f488c6e22ae5ead8d',
  chains
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient
})



function App() {
  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        {/* <QueryClientProvider client={QueryClient}> */}
          <RainbowKitProvider
            chains={chains}
            appInfo={{
              appName: "catchcoin",
              learnMoreUrl: "https://academy.binance.com/en/glossary/wallet",
            }}
          >
            {/*             
<Header/>
            <Spacing lg='80' md='80'/> */}
            <Routes>
              <Route path="/" element={<Index />} />
              {/* <Route path="/home2" element={<Home2 />} /> */}
              <Route path="/terms" element={<Terms />} />
              <Route path="/privacy" element={<Privacy />} />
              {/* <Route path="/news" element={<News/>}/> */}
              {/* <Route path="/home3" element={<Home3 />} /> */}
              {/* <Route path="/portal" element={<Portal />} /> */}
              {/* <Route path="/blog/:id" element={<BlogDetails />} /> */}
              <Route path="*" element={<ErrorPage />} />
            </Routes>
            <CookieConsent
              enableDeclineButton={true}
              flipButtons
              onAccept={(acceptedByScrolling) => {
                window.consentGranted()

                // if (acceptedByScrolling) {
                //   // triggered if user scrolls past threshold
                //   alert("Accept was triggered by user scrolling");
                // } else {
                //   alert("Accept was triggered by clicking the Accept button");
                // }
              }}
            >
              This website uses cookies to enhance the user experience and measure performance. Details in our <Link spy={true} smooth={true} offset={-80} duration={500} to='/privacy' target="_blank">Privacy Policy</Link>.
            </CookieConsent>
 
            <ToastContainer />
          </RainbowKitProvider>
        {/* </QueryClientProvider> */}
      </WagmiConfig>
    </>
  );
}

export default App;
