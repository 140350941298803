import React, { useContext, useEffect } from 'react'

import { Parallax } from 'react-parallax';
import parse from 'html-react-parser';
import './hero.scss'
import Section from '../Section';
import Button from '../Button';
import Modal from '../Modal';
import { walletContext } from '../../contexts/walletContext'
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useAccount, useNetwork } from "wagmi";
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { Stack, Typography } from "@mui/material";
import Slider from "react-slick";


export default function Web3Hero({ title, bgUrl, heroImageUrl, GifUrl, animatedUrl, pinksaleImage, reflectionsImage, pancakeswapImage, pancakeswapImageSecond, pinksaleFinalImage, variant, bubble, overlay, bgOpacity }) {
  // const { account } = useContext(walletContext)
  // const { address } = useAccount();

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear"
  };


  return (

    <Section tag='section' className={`cs-hero cs-style1 ${variant ? variant : ''}`} id="hero" >


      <Section className="cs-hero_bg">
        <img src={bgUrl} className="hero-image" alt="Hero" />

        {/* <Parallax bgImage={bgUrl} bgImageAlt="Hero" strength={120}></Parallax> */}

      </Section>

      <Section className="container">

        <Section className="cs-hero_text">

          <Section tag='h2' className="cs-hero_title cs-font_44 cs-font_36_sm cs-bold">{parse(title)}</Section>

          {/* <Section className="cs-btn_group">
            <Button
              variant='cs-btn_filed cs-accent_btn mt-2'
              btnLink='https://app.uniswap.org/swap?inputCurrency=0x4200000000000000000000000000000000000006&outputCurrency=0x95017e6f16375e63e5cb4d3a5fbf3c40775b08f4&chainId=10'
              btnText='Buy CATCH'>
            </Button>
            <Button
              variant='cs-btn_filed cs-accent_btn mt-2 '
              btnLink='https://www.dextools.io/app/en/base/pair-explorer/0xcd7a1f6cdc75ea4826d1418046dfc480f202eb72?t=1713799320981'
              btnText='DEXTools'>
            </Button>

          </Section> */}
          <Section className="cs-btn_group">

          <Button
              variant='cs-btn_filed cs-accent_btn mt-2 '
              btnLink='https://t.me/catchcoindiscussion'
              btnText='Telegram'>
            </Button>

            <Button
              variant='cs-btn_filed cs-accent_btn mt-2 '
              btnLink='pdf/Whitepaper.pdf'
              btnText='Whitepaper'>
            </Button>
            <Button
              variant='cs-btn_filed cs-accent_btn mt-2 '
              btnLink='pdf/Pitchdeck.pdf'
              btnText='Pitchdeck'>
            </Button>

            {/* <ConnectButton.Custom>
              {({
                account,
                chain,
                openAccountModal,
                openChainModal,
                openConnectModal,
                authenticationStatus,
                mounted,
              }) => {
                // Note: If your app doesn't use authentication, you
                // can remove all 'authenticationStatus' checks
                const ready = mounted && authenticationStatus !== "loading";
                const connected =
                  ready &&
                  account &&
                  chain &&
                  (!authenticationStatus || authenticationStatus === "authenticated");

                return (
                  <div
                    {...(!ready && {
                      "aria-hidden": true,
                      style: {
                        opacity: 0,
                        pointerEvents: "none",
                        userSelect: "none",
                        paddingTop: "10px",
                      },
                    })}
                  >
                    {(() => {
                      if (!connected) {
                        return (
                          ''
                        )
                      }
                      // console.log(chain.id);
                      if (chain.unsupported && chain.id !== 56 && chain.id !== 97) {
                        return (
                          <Button
                            onClick={openChainModal}
                            variant={"contained"}
                            color={"error"}
                          >
                            Wrong network
                          </Button>
                        );
                      }

                      return (
                        ''

                        // <Modal modalType='whitelist' account={account} btnText='Whitelist Now' />
                      );

                    })()}
                  </div>
                );
              }}
            </ConnectButton.Custom> */}

            {/* <Button 
                btnLink='/' 
                btnText='Join the Waitlist' 
                variant='cs-color1'
              /> */}
          </Section>

        </Section>
        <Section className="cs-hero_img">
          <div className="slider-container">
            <Slider {...settings}>
              <div>
                <img src='images/catch-on-base.webp' alt="catch coin on base" style={{ textAlign: 'center' }} />
              </div>

              <div style="height:100%">
              <div class="video-container">
                <iframe
                  src="https://www.youtube.com/embed/iwE6p4ifkpk"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope;"
                  allhowfullscreen></iframe>
              </div>
              </div>
              {/* <div>
                <img src={GifUrl} alt="catchcoin 3d coin" style={{ textAlign: 'center', width: '100%' }} /> */}

                {/* <video width="320" height="240" controls autoplay>
  <source src="videos/catchcoin_3d_spin.mp4" type="video/mp4" />
  <source src="videos/catchcoin_3d_spin.mp4" type="video/ogg" />
  Your browser does not support the video tag.
</video> */}
              {/* </div> */}

              <div>
                <img src={heroImageUrl} alt="catchcoin mobile app" style={{ textAlign: 'center' }} />
              </div>
              {/* <div>
                <a href="https://www.pinksale.finance/launchpad/bsc/0x15DcA20995378DEfaAdaad7A1FF49cd0141D79c1" target="_blank"><img src={pinksaleImage} alt="catchcoin Pinksale | CATCH" /></a>
              </div> */}
              <div>
                <img src={reflectionsImage} alt="generate foot traffic" />
              </div>
              {/* <div>
                <a href="https://pancakeswap.finance/swap?outputCurrency=0x95017e6f16375e63e5cb4d3a5fbf3c40775b08f4" target="_blank"><img src={pancakeswapImage} alt="catchcoin Pancakeswap | CATCH" /></a>
              </div> */}

              
              {/* <div>
                <img src={pinksaleFinalImage} alt="Hero" />
              </div> */}

            </Slider>
          </div>




          {/* <Section className="cs-hero_img_sm">
                            <img src={animatedUrl} alt="Hero" />
                        </Section> */}

        </Section>

      </Section>

    </Section>
  )
}
