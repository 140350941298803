import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import Section from '../Section'

export default function Button({btnLink, btnText, variant, children}) {
  return (
    <Link to={btnLink} target="_blank" className={`cs-btn ${variant?variant:''} `}
    sx={{
      bgcolor: 'primary.dark',
    }}>
      {children}
      <Section tag='span'>{btnText}</Section>
    </Link>
  )
}
