import React, { useEffect } from 'react'
import { pageTitle } from '../../helper'
import Section from '../Section'
import SectionHeading from '../SectionHeading'
import Spacing from '../Spacing'

export default function Privacy() {
  pageTitle('Privacy Policy');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // const privacyText = '<p><em>Last Updated: 29-11-2023</em></p><p>This Privacy Policy outlines how Catchcoin ("we," "us," or "our") collects, uses, and protects your personal information. By using our services, you agree to the terms outlined in this policy.</p><h2>Information We Collect</h2><p>We may collect the following types of personal information:</p><ul><li>Contact Information: Your name, email address, and other contact details.</li><li>Account Information: Usernames, passwords, and other account-related information.</li><li>Financial Information: Information related to transactions and payments.</li><li>Usage Data: Information about how you interact with our services.</li></ul><h2>How We Use Your Information</h2><p>We may use your personal information for the following purposes:</p><ul><li>Provide and maintain our services.</li><li>Process transactions and send transaction notifications.</li><li>Respond to your inquiries and provide support.</li><li>Improve and personalize user experience.</li><li>Send periodic emails regarding our services and updates.</li></ul><h2>Information Sharing</h2><p>We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as described in this Privacy Policy or as required by law.</p><h2>Security</h2><p>We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p><h2>Contact Us</h2><p>If you have any questions or concerns about our Privacy Policy, please contact us at <a href="mailto:info@catchcoin.com">info@catchcoin.com</a>.</p><p>Thank you for choosing Catchcoin!</p>';

  const privacyText = `<h1>Privacy Policy for Google Analytics</h1>

  <p>This Privacy Policy outlines how Google Analytics collects, uses, maintains, and discloses information gathered from users of websites or mobile applications that utilize Google Analytics services.</p>
  
  <h2>Information Collection and Usage:</h2>
  
  <p>Google Analytics may collect various types of information, including but not limited to:</p>
  
  <ol>
      <li><strong>Device Information:</strong> Information about the device accessing the website or application, such as device type, operating system, and browser type.</li>
      <li><strong>Log Data:</strong> Data automatically collected by Google Analytics servers, including IP addresses, browser type, pages visited, referring website, and timestamps.</li>
      <li><strong>Cookies and Tracking Technologies:</strong> Google Analytics employs cookies and similar tracking technologies to analyze user interactions with websites or applications, track user behavior, and gather demographic information. These technologies may collect information such as user preferences, browsing history, and interactions with advertisements.</li>
      <li><strong>Location Information:</strong> Google Analytics may collect information about the geographic location of users, based on IP address or other methods.</li>
  </ol>
  
  <h2>Data Usage:</h2>
  
  <p>The information collected by Google Analytics is utilized for the following purposes:</p>
  
  <ol>
      <li><strong>Website/Application Analytics:</strong> Analyzing user behavior and interactions with websites or applications to optimize content, improve user experience, and provide relevant advertisements.</li>
      <li><strong>Audience Insights:</strong> Generating reports and insights about website or application users, including demographics, interests, and behavior patterns.</li>
      <li><strong>Advertising:</strong> Providing personalized advertisements based on user preferences and behavior, both on the website or application and across other websites and platforms.</li>
  </ol>
  
  <h2>Data Sharing:</h2>
  
  <p>Google Analytics may share collected information with third-party service providers, partners, and affiliates for the purposes outlined in this Privacy Policy. However, Google Analytics does not disclose personal information to third parties for their direct marketing purposes without user consent.</p>
  
  <h2>Data Security:</h2>
  
  <p>Google Analytics implements appropriate security measures to protect against unauthorized access, alteration, disclosure, or destruction of collected information. However, it is important to note that no method of data transmission over the Internet or electronic storage is 100% secure.</p>
  
  <h2>User Choices:</h2>
  
  <p>Users can opt out of Google Analytics tracking by adjusting their browser settings to disable cookies or using Google's opt-out browser add-on. Additionally, users may choose to disable personalized advertising through Google's Ad Settings.</p>
  
  <h2>Policy Updates:</h2>
  
  <p>This Privacy Policy may be updated from time to time, and users are encouraged to review the policy periodically for any changes. Continued use of websites or applications utilizing Google Analytics services constitutes acceptance of any revisions to this policy.</p>
  
  <h2>Contact Information:</h2>
  
  <p>If you have any questions or concerns about this Privacy Policy or Google Analytics practices, please contact us at [ info@catchcoin.com ] .</p>
  
  <p>Last Updated: Tuesday, May 28, 2024</p>
  `;
  
  return (
    <>
      <Section tag='section' id='about'>
        <Spacing lg='100' md='70'/>
        <Section className="container">
          <Section className="row align-content-center">
            <Section className="col">
              <Section className='cs-right_space_40'>
                <SectionHeading 
                  title='Privacy Policy'
                />
                <Spacing lg='20' md='20'/>
                <Section tag='div' dangerouslySetInnerHTML={{__html: privacyText}}>
                    




                    
                </Section>
              </Section>
              <Spacing lg='0' md='40'/>
            </Section>
          </Section>
        </Section>
        <Spacing lg='100' md='70'/>
      </Section>
    </>
  )
}
